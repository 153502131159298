import React, { useRef } from 'react'
import { Link, graphql } from "gatsby";
import {
  mapEdgesToNodes,
} from "../lib/helpers";
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image";
import { device } from '../styles/mediaQueries';
import { colors } from "../styles/custom-properties";
import Container from "../components/container";
import Header from "../components/header/header"
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout"
import ExhibitionsWrapper from "../components/exhibitions/exhibition-wrapper"

export const query = graphql`
  query IndexPageQuery {
    exhibitions: allSanityExhibitions {
      edges {
        node {
          id
          title
          exhibitionDate
          slug {
            current
          }
        }
      }
    }
  }
`;

const Column = styled.div` 
  flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media ${device.mediaMinLarge} {
    align-items: center;
    justify-content: center;
  }
  h1 {
    font-family: 'Work Sans',sans-serif;
    text-transform: uppercase;
    font-size: 2.4rem;
    color: rgb(50, 55, 62);
  }

  .contactImgWrapper {
    height: 100%;
    max-height: auto;
    /* @media ${device.mediaMinMedium} {
      max-height: 80vh;
    } */

  }

  &.text-content-wrapper {
    padding: 0;
    width: 100%;
    @media ${device.mediaMinLarge} {
      padding: 0 0 0 2rem;
    }
  }

  &.contact-column-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.clipped {
    --clip: 0 100% 0 0;
    clip-path: inset(var(--clip));
  }

  .heroTitle {
    margin: 0;
  }
  
  .heroTitle,.heroText {
    visibility: hidden;
  }
`

const ExhibitionsList = styled.ul` 
  list-style: none;
  margin: 0;
  padding: 0;
  a {
    color: black;
    text-decoration: none;
    &:hover {
      color: ${colors.colorTurquoise};
    }
  }
`

const Exhibitions = (props) => {
  const { data, errors } = props;
  
  const exhibitionsNodes = (data || {}).exhibitions
  ? mapEdgesToNodes(data.exhibitions)
  : []

  const ref = useRef(null)

  return (
    <Layout>
      {/* <SEO title="about" description={site.description} keywords={site.keywords} /> */}
      <SEO title="exhibitions" />
        <ExhibitionsWrapper ref={ref} style={{height: '100vh'}}>
          <Column className='contact-column-right'>
            <div className='text-content-wrapper'>
              <h1 className=''>Exhibitions</h1>
              {/* <p className=''>More info coming soon...</p> */}
              <ExhibitionsList>
                {exhibitionsNodes.map((exhibition) => {
                  console.log('exhibition:', exhibition)
                  return (
                    <li key={exhibition.id}>
                      <Link to={`/exhibition/${exhibition.slug.current}`} >{exhibition.exhibitionDate}</Link>
                    </li>
                  )
                })}
              </ExhibitionsList>
            </div>
          </Column>
          <Column className=''>
            <StaticImage
                src="../assets/exhibitions.jpeg"
                alt="About"
                placeholder="blurred"
                layout="constrained"
                fit="contain"
                // objectPosition="0 0"
                className="contactImgWrapper"
                imgClassName='contactImg'
            />
          </Column>
        </ExhibitionsWrapper>
    </Layout>
  )
}

export default Exhibitions